export const Types = {
  getters: {
    GET_LOADING_STATUS: "GET_LOADING_STATUS",
    GET_PROJECT_LIST: "GET_PROJECT_LIST",
    GET_MODAL_DETAIL: "GET_MODAL_DETAIL",
    GET_PROJECT_DETAIL: "GET_PROJECT_DETAIL",
    GET_FILTERED_PROJECT: "GET_FILTERED_PROJECT",
    GET_TABLES: "GET_TABLES",
    GET_PROMPTS: "GET_PROMPTS",
    GET_INDIVIDUAL_PROMPT: "GET_INDIVIDUAL_PROMPT",
    GET_PASTE_TABLE_CONTENT: "GET_PASTE_TABLE_CONTENT",
    GET_DOCUMENTS: "GET_DOCUMENTS",
    GET_COLLECTIONS: "GET_COLLECTIONS",
    GET_SELECTED_ROW: "GET_SELECTED_ROW",
    GET_SETTINGS: "GET_SETTINGS",
    GET_RESULT_STATUS: "GET_RESULT_STATUS",
    GET_LOADING_TEXT: "GET_LOADING_TEXT",
    GET_RESULT_LIST: "GET_RESULT_LIST",
    GET_TABLE_STATUS: "GET_TABLE_STATUS",
    GET_TNC_CHECK: "GET_TNC_CHECK",
    GET_PROJECT_PROMPTS: "GET_PROJECT_PROMPTS",
  },
  mutations: {
    SET_PROJECT_LIST: "SET_PROJECT_LIST",
    SET_MODAL_DETAIL: "SET_MODAL_DETAIL",
    SET_PROJECT_DETAIL: "SET_PROJECT_DETAIL",
    SET_PROJECT_FILTER: "SET_PROJECT_FILTER",
    SET_TABLES: "SET_TABLES",
    SET_LOADING_STATUS: "SET_LOADING_STATUS",
    SET_PROMPTS: "SET_PROMPTS",
    SET_INDIVIDUAL_PROMPT: "SET_INDIVIDUAL_PROMPT",
    SET_NEW_PROMPT: "SET_NEW_PROMPT",
    SET_SNACKBAR: "SET_SNACKBAR",
    SET_UPDATE_RESULTS: "SET_UPDATE_RESULTS",
    SET_DOCUMENTS: "SET_DOCUMENTS",
    SET_COLLECTIONS: "SET_COLLECTIONS",
    SET_SELECTED_ROW: "SET_SELECTED_ROW",
    SET_RESULT_STATUS: "SET_RESULT_STATUS",
    SET_SETTINGS: "SET_SETTINGS",
    SET_UPDATE_SETTING: "SET_UPDATE_SETTING",
    SET_TABLE_RELATIONSHIPS: "SET_TABLE_RELATIONSHIPS",
    SET_LOADING_TEXT: "SET_LOADING_TEXT",
    SET_RESULT_LIST: "SET_RESULT_LIST",
    SET_TABLE_STATUS: "SET_TABLE_STATUS",
    SET_TNC_CHECK: "SET_TNC_CHECK",
    SET_PROJECT_PROMPTS: "SET_PROJECT_PROMPTS",
  },
  actions: {
    DO_PROJECT_LIST: "DO_PROJECT_LIST",
    DO_CREATE_PROJECT: "CREATE_PROJECT",
    DO_CREATE_COLLECTION: "CREATE_COLLECTION",
    DO_MODAL_DETAIL: "DO_MODAL_DETAIL",
    DO_PROJECT_DETAIL: "DO_PROJECT_DETAIL",
    DO_PROJECT_FILTER: "DO_PROJECT_FILTER",
    DO_ADD_USER: "DO_ADD_USER",
    DO_GET_TABLES: "DO_GET_TABLES",
    DO_GET_PROMPTS: "DO_GET_PROMPTS",
    DO_ADD_NEW_PROMPT: "DO_ADD_NEW_PROMPT",
    DO_UPLOAD_TABLE: "DO_UPLOAD_TABLE",
    DO_UPDATE_RESULT: "DO_UPDATE_RESULT",
    DO_UPDATE_SNACKBAR: "DO_UPDATE_SNACKBAR",
    DO_GET_DOCUMENT: "DO_GET_DOCUMENT",
    DO_GET_COLLECTION: "DO_GET_COLLECTION",
    DO_UPDATE_COLLECTION: "DO_UPDATE_COLLECTION",
    DO_ADD_TO_PROJECT: "DO_ADD_TO_PROJECT",
    DO_UPDATE_SELECTED_ROW: "DO_UPDATE_SELECTED_ROW",
    DO_UPDATE_TABLE_STATUS: "DO_UPDATE_TABLE_STATUS",
    DO_DOC_TO_PROJECT: "DO_DOC_TO_PROJECT",
    DO_DELETE_PROJECT: "DO_DELETE_PROJECT",
    DO_DELETE_DOCUMENT: "DO_DELETE_DOCUMENT",
    DO_DELETE_COLLECTION: "DO_DELETE_COLLECTION",
    DO_DELETE_USER: "DO_DELETE_USER",
    DO_DELETE_TABLE: "DO_DELETE_TABLE",
    DO_DELETE_PROMPT: "DO_DELETE_PROMPT",
    DO_DELETE_RESULT: "DO_DELETE_RESULT",
    DO_FEED_BACK: "DO_FEED_BACK",
    DO_UPDATE_SETTING: "DO_UPDATE_SETTING",
    DO_ADJUST_RESULT: "DO_ADJUST_RESULT",
    DO_RELATIONSHIPS: "DO_RELATIONSHIPS",
    DO_DELETE_RELATIONSHIP: "DO_DELETE_RELATIONSHIP",
    DO_REVERT_RESULT: "DO_REVERT_RESULT",
    DO_APPLY_OWN_RESULT: "DO_APPLY_OWN_RESULT",
    DO_IS_LOADING: "DO_IS_LOADING",
    DO_MODIFY_COLLECTION: "DO_MODIFY_COLLECTION",
    DO_FAVOURITE_COLLECTION: "DO_FAVOURITE_COLLECTION",
    DO_FETCH_USERS: "DO_FETCH_USERS",
    DO_TNC_CHECK: "DO_TNC_CHECK",
    DO_ADD_TAGS: "DO_ADD_TAGS",
    DO_GET_PROJECT_PROMPTS: "DO_GET_PROJECT_PROMPTS",
    DO_EDIT_PROMPT: "DO_EDIT_PROMPT",
    DO_REMOVE_PROMPT: "DO_REMOVE_PROMPT",
    DO_IMPORT_PROMPTS: "DO_IMPORT_PROMPTS"
  },
};
