export const state = () => ({
  projectDetail: {},
  isLoading: {
    global: false
  },
  isTableRelationshipLoading: false,
  tables: {},
  tableList: [],
  filteredTableList: [],
  modal: {
    name: "",
    showDialog: false,
    errorMessage: "",
    params: {}
  },
  results: {},
  snackbar: {},
  documents: [],
  collections: [],
  selectedTableRow: [],
  settings: {},
  vectorsStatus: {},
  resultStatus: {},
  tableStatus: {},
  isUserLoading: false,
  tableRelationships: {},
  tableStatusTimer: null,
  isResultLoading: false,
  isLoadingText: '',
  resultList: {},
  feedback: {},
  projectPrompts:[]
});
